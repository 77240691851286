<div class="wrapper">
    <mat-card class="form-card text-center bg-white mat-elevation-z3">
        <form [formGroup]="loginForm">
            <app-auth-top [title]="'Witamy'"
                          [subTitle]="'Zaloguj się na swoje konto'"></app-auth-top>
            <mat-card-content>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>e-mail</mat-label>
                    <input matInput formControlName="email" name="email" type="email" placeholder="e-mail" #email>
                    <mat-hint align="end">
                        {{ email.value.length }} / {{ emailMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="emailAddress.errors?.['email'] || emailAddress.errors?.['required']">
                        Wpisz poprawny adres e-mail.
                    </mat-error>
                    <mat-error *ngIf="email.value.length > emailMaxLength && !emailAddress.errors?.['email']">
                        Maksymalna długość adresu e-mail wynosi {{ emailMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width pt-2" appearance="outline">
                    <mat-label>hasło</mat-label>
                    <input matInput formControlName="password" name="password" type="password" placeholder="password"
                           #passwd>
                    <mat-hint align="end">
                        {{ passwd.value.length }} / {{ passwordMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="password.errors?.['required']">
                        Podaj hasło.
                    </mat-error>
                    <mat-error *ngIf="passwd.value.length < passwordMinLength && !password.errors?.['required']">
                        Minimalna długość hasła wynosi {{ passwordMinLength }} znaków.
                    </mat-error>
                    <mat-error *ngIf="passwd.value.length > passwordMaxLength">
                        Maksymalna długość hasła wynosi {{ passwordMaxLength }} znaków.
                    </mat-error>
                </mat-form-field>
                <div class="pt-2">
                    <button type="button" class="full-width" mat-stroked-button color="primary"
                            matTooltip="Logowanie do aplikacji" matTooltipPosition="above" (click)="login()">
                        Zaloguj <i class="fa-solid fa-right-to-bracket"></i></button>
                </div>
            </mat-card-content>
            <div class="text-center py-4">
                <a class="link-secondary" routerLink="/auth/password"
                   matTooltip="Link do przymomnienia hasła" matTooltipPosition="right">Zapomniałeś hasła?</a>
                <div class="pt-2">
                    <span>Jeśli nie masz konta, załóż: </span>
                    <a class="link-secondary" routerLink="/auth/register"
                       matTooltip="Link do formularza rejestracyjnego" matTooltipPosition="right">rejestracja</a><span>.</span>
                </div>
            </div>
        </form>
    </mat-card>
    <div class="mt-2">
        <app-company-link></app-company-link>
    </div>
</div>
