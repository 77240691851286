<div *ngIf="isLoading; else content">
    <div class="wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #content>
    <div class="wrapper">
        <mat-card class="form-card text-center bg-white mat-elevation-z3">
            <form [formGroup]="passForgotForm">
                <app-auth-top [title]="'Zapomniane hasło'"
                              [subTitle]="'Wpisz swój adres e-mail'"
                              [subTitleSecondLine]="'by otrzymać link do resetowania hasła.'"
                              [closeLink]="'/auth/login'"></app-auth-top>
                <mat-card-content>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>e-mail</mat-label>
                        <input matInput formControlName="email" name="email" type="email" placeholder="e-mail" #email>
                        <mat-hint align="end">
                            {{ email.value.length }} / {{ emailMaxLength }}
                        </mat-hint>
                        <mat-error *ngIf="emailAddress.errors?.['email'] || emailAddress.errors?.['required']">
                            Podaj prawidłowy adres e-mail.
                        </mat-error>
                        <mat-error *ngIf="email.value.length > emailMaxLength && !emailAddress.errors?.['email']">
                            Maksymalna długość adresu e-mail wynosi {{ emailMaxLength }} znaków.
                        </mat-error>
                    </mat-form-field>
                    <div class="pt-2">
                        <button type="button" class="full-width" mat-stroked-button color="primary"
                                [disabled]="passForgotForm.invalid"
                                matTooltip="Wysłanie danyh" matTooltipPosition="above" (click)="forgotPassword()">
                            Wyślij <i class="fa-solid fa-paper-plane"></i></button>
                    </div>
                </mat-card-content>
                <div class="text-center py-4">
                    <a class="link-secondary" routerLink="/auth/login">Powrót do logowania</a>
                </div>
            </form>
        </mat-card>
        <div class="mt-2">
            <app-company-link></app-company-link>
        </div>
    </div>
</ng-template>
