import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

// services
import {AuthService} from "../../shared/services/auth.service";
import {SnackbarService} from "../../shared/services/snackbar.service";

// models
import {Credentials} from "../../shared/models/user/credentials";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './../auth.component.scss']
})
export class LoginComponent implements OnInit {

    public loginForm!: FormGroup;

    public emailMaxLength = 100;
    public passwordMinLength = 8;
    public passwordMaxLength = 40;

    constructor(private formBuilder: FormBuilder,
                private authService: AuthService,
                private snackbarService: SnackbarService,
                private router: Router) {
    }

    public ngOnInit(): void {
        this.buildLoginForm();
    }

    public get emailAddress(): AbstractControl {
        return this.loginForm.controls['email'];
    }

    public get password(): AbstractControl {
        return this.loginForm.controls['password'];
    }

    public login(): void {
        this.authService.login(this.loginForm.getRawValue()).subscribe({
            next: (credentials: Credentials): void => {
                if (credentials) {
                    this.authService.isLogged = true;
                    const returnURL: string | null = localStorage.getItem('returnURL');
                    console.log('login type: ' + credentials.type);
                    if (credentials.type === 'admin') {
                        this.authService.isAdmin = true;
                        this.authService.isMember = false;
                        this.authService.isUser = false;
                    }
                    if (credentials.type === 'member') {
                        this.authService.isAdmin = false;
                        this.authService.isMember = true;
                        this.authService.isUser = false;
                    }
                    if (credentials.type === 'user') {
                        this.authService.isAdmin = false;
                        this.authService.isMember = false;
                        this.authService.isUser = true;
                    }
                    this.router.navigateByUrl(returnURL || '/secure').then();
                }
            },
            error: () => this.snackbarService.snackbarAuthPassError(),
            complete: (): void => {
                this.authService.isLogged = true;
                this.snackbarService.snackbarCustomComplete('Witamy, zostałeś zalogowany!');
            }
        });
    }

    private buildLoginForm(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', {validators: [Validators.required, Validators.email, Validators.maxLength(this.emailMaxLength)]}],
            password: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]
            }]
        });
    }
}
