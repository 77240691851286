<a class="d-flex flex-row-reverse link-secondary mx-2 mt-2" routerLink="{{ closeLink }}">
    <mat-icon aria-hidden="false" matTooltip="Zamknij" matTooltipPosition="below">close</mat-icon>
</a>
<img mat-card-image class="form-card-logo"
     ngSrc="../../../../assets/images/logo/derenda-logo.svg"
     alt="DerendaApp Logo" title="DerendaApp Logo"
     height="100" width="300" priority="">
<div class="py-2">
    <h2>{{ title }}</h2>
    <p class="text-secondary">{{ subTitle }}
        <span *ngIf="subTitleSecondLine !== ''"><br/>{{ subTitleSecondLine }}</span></p>
</div>
