<div *ngIf="isLoading; else content">
    <div class="wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #content>
    <div class="wrapper">
        <mat-card class="form-card text-center bg-white mat-elevation-z3">
            <form [formGroup]="passwordResetForm">
                <app-auth-top [title]="'Zmiana hasła'"
                              [subTitle]="'Wypełni formularz aby zmienić hasło.'"
                              [closeLink]="'/auth/login'"></app-auth-top>
                <mat-card-content>
                    <mat-form-field class="full-width pt-1" appearance="outline">
                        <mat-label>e-mail</mat-label>
                        <input matInput [formControl]="emailFormControl">
                    </mat-form-field>
                    <mat-form-field class="full-width pt-1" appearance="outline">
                        <mat-label>hasło</mat-label>
                        <input matInput formControlName="password" name="password" type="password"
                               placeholder="hasło" #passwd>
                        <mat-hint align="end">
                            {{ passwd.value.length }} / {{ passwordMaxLength }}
                        </mat-hint>
                        <mat-error *ngIf="password.errors?.['required']">
                            Podaj hasło.
                        </mat-error>
                        <mat-error *ngIf="passwd.value.length < passwordMinLength && !password.errors?.['required']">
                            Minimalna długość wynosi {{ passwordMinLength }} znaków.
                        </mat-error>
                        <mat-error *ngIf="passwd.value.length > passwordMaxLength">
                            Maksymalna długość wynosi {{ passwordMaxLength }} znaków.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width pt-1" appearance="outline">
                        <mat-label>ponów hasło</mat-label>
                        <input matInput formControlName="password_confirmation" name="password_confirmation"
                               type="password" placeholder="ponów hasło">
                        <mat-error *ngIf="password_confirmation.errors?.['required']">
                            Podaj hasło ponownie.
                        </mat-error>
                        <mat-error
                            *ngIf="password_confirmation.errors?.['confirmedValidator'] || password_confirmation.errors?.['touched']">
                            Hasło oraz ponowienie hasła muszą się zgadzać.
                        </mat-error>
                    </mat-form-field>
                    <div class="pt-3">
                        <button type="button" class="full-width" mat-stroked-button color="primary"
                                [disabled]="passwordResetForm.invalid"
                                matTooltip="Zapisz nowe hasło" matTooltipPosition="above"
                                (click)="passwordReset()">
                            Zapisz <i class="fa-solid fa-floppy-disk"></i></button>
                    </div>
                </mat-card-content>
                <div class="py-4">
                    <span>Jeżeli nie chcesz zmieniać hasła wróć na stronę: </span>
                    <a class="link-secondary" routerLink="/auth/login"
                       matTooltip="Powrót do strony logowania" matTooltipPosition="right">logowania</a><span>.</span>
                </div>
            </form>
        </mat-card>
        <div class="mt-2">
            <app-company-link></app-company-link>
        </div>
    </div>
</ng-template>
